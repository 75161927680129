import React from 'react';
import './style.scss';

const Footer = () => {

  return (
    <footer className='footer'>
      &copy; 2020 Alberth Pérez
    </footer>
  );
}

export default Footer;